import { useStaticQuery, graphql } from "gatsby"

export const usePlay = () => {
  const data = useStaticQuery(
    graphql`query playQuery {
  allFile(
    filter: {relativeDirectory: {eq: "play"}}
    sort: {order: ASC, fields: name}
  ) {
    nodes {
      childImageSharp {
        gatsbyImageData(quality: 100, placeholder: NONE, layout: FULL_WIDTH)
      }
    }
  }
}
`
  )
  return data
}
