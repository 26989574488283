import React from "react"
import { Helmet } from "react-helmet"
import { usePlay } from "../hooks/use-play"
import { GatsbyImage } from "gatsby-plugin-image";
import Reveal from "../components/reveal"

export default function Play() {
  const plays = usePlay()
  console.log(plays)
  return <>
    <Helmet>
      <meta charSet="utf-8" />
      <meta name="Description" content="Lance – Process"></meta>

      <title>Lance / Play</title>
      <link rel="preload" href="/fonts/fonts.css" as="style"></link>
      <body className="scrollSmooth" />
    </Helmet>

    <>
      <div className="mt-14 pb-48">
      <div className="px-3 border border-realGray-800 border-solid pt-3 my-3 md:my-32"><GatsbyImage
        image={plays.allFile.nodes[0].childImageSharp.gatsbyImageData}
        className="rounded-lg mb-3" />
      <h5 className="pb-3 text-base pt-10 font-normal">Calligraphy — Nike "Just do it" tagline</h5>
      </div>

          
          <div className="px-3 ml-auto max-w-4xl border border-realGray-800 border-solid pt-3 my-3 md:my-32">
            <GatsbyImage
              image={plays.allFile.nodes[1].childImageSharp.gatsbyImageData}
              className="rounded-xl" />
            <h5 className="pb-3 text-base pt-10 font-normal">Sketch inspired from the original logo</h5>
          </div>

          
          <div className="px-3 ml-auto max-w-4xl border border-realGray-800 border-solid pt-3 my-3 md:my-32">
            <GatsbyImage
              image={plays.allFile.nodes[2].childImageSharp.gatsbyImageData}
              className="rounded-xl" />
            <h5 className="pb-3 text-base pt-10 font-normal">Rough sketch</h5>
          </div>

          
          <div className="px-3 max-w-4xl border border-realGray-800 border-solid pt-3 my-3 md:my-32">
            <GatsbyImage
              image={plays.allFile.nodes[3].childImageSharp.gatsbyImageData}
              className="rounded-xl" />
            <h5 className="pb-3 text-base pt-10 font-normal">Meli-Melo base sketch</h5>
          </div>


          
          <div className="px-3 ml-auto max-w-3xl border border-realGray-800 border-solid pt-3 my-3 md:my-32">
            <GatsbyImage
              image={plays.allFile.nodes[4].childImageSharp.gatsbyImageData}
              className="rounded-xl" />
            <h5 className="pb-3 text-base pt-10 font-normal">Sketches from exploration phase</h5>
          </div>

          <Reveal once={true}>
          <div className="px-3 max-w-4xl border border-realGray-800 border-solid pt-3 my-3 md:my-32">
            <GatsbyImage
              image={plays.allFile.nodes[5].childImageSharp.gatsbyImageData}
              className="rounded-xl" />
            <h5 className="pb-3 text-base pt-10 font-normal">Exploration phase</h5>
          </div>
          </Reveal>


          <div className="px-3 ml-auto max-w-3xl border border-realGray-800 border-solid pt-3 my-3 md:my-32">
            <GatsbyImage
              image={plays.allFile.nodes[6].childImageSharp.gatsbyImageData}
              className="rounded-xl" />
            <h5 className="pb-3 text-base pt-10 font-normal">Refinement notes</h5>
          </div>



          <div className="px-3 max-w-3xl border border-realGray-800 border-solid pt-3 my-3 md:my-32">
            <GatsbyImage
              image={plays.allFile.nodes[7].childImageSharp.gatsbyImageData}
              className="rounded-xl" />
            <h5 className="pb-3 text-base pt-10 font-normal">Voiceflow base sketch</h5>
          </div>


          <GatsbyImage
            image={plays.allFile.nodes[8].childImageSharp.gatsbyImageData}
            className="rounded-lg" />
      </div>
    </>
  </>;
}
